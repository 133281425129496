.detail-card > div > div > div.ant-card-head-title {
  padding: 0px;
}
.ant-popover-wrapper > .ant-popover-content > .ant-popover-arrow {
  visibility: hidden;
}
.ant-popover-wrapper > .ant-popover-content > .ant-popover-inner > div > .ant-popover-inner-content {
  padding: 0 4px;
}
.ant-popover-wrapper.ant-popover-placement-bottomLeft {
  padding-top: 0;
}
